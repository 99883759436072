<template>
  <main id="mainpage"  class="main-content position-relative max-height-vh-100 h-100 overflow-x-hidden">
    
    
    <router-view />
    <configurator
      :toggle="toggleConfigurator"
      :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
    />
    
  </main>
</template>

<script>

import Configurator from "@/fragments/Configurator.vue";
import { mapMutations, mapState } from "vuex";

export default {
  name: 'App',
  components: {
    Configurator
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
    
  },mounted() {
    
  },
  computed: {
    ...mapState([
      "isAbsolute",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showConfig",
      "hideConfigButton"
    ])
  },
}
</script>

