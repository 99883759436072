import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";

const routes = [
  {
    path: "/",
    name: "/",
    component: () => import(/* webpackChunkName: "SignIn" */ "../components/signin.vue"),
    meta: { requiresAuth: false },
  },
  
  
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/dashboard.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/reports",
    name: "reports",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/reports.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/examreports",
    name: "examreports",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/examreports.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/assignments",
    name: "Assignments",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/assignments.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/selftests",
    name: "Selftests",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/assignments.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/myexams",
    name: "Myexams",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/mainexams.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/ai",
    name: "Ai",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/ai.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/vodeoclasses",
    name: "vodeoclasses",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/videoclasses.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/buslocation",
    name: "bus",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/bus.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/liveclasses",
    name: "liveclasses",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/liveclasses.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/pastpapers",
    name: "pastpapers",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/pastpapers.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/notes",
    name: "notes",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/notes.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/quizzes",
    name: "quizzes",
    component: () => import(/* webpackChunkName: "Hr" */ "../components/Elearning/quizes.vue"),
    meta: { requiresAuth: true },
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  
  scrollBehavior(to, from, SavedPosition) {
    if (to.hash) {
      const el = window.location.href.split("#")[1];
      if (el.length) {
        document.getElementById(el).scrollIntoView({ behavior: "smooth" });
      }
    } else if (SavedPosition) {
      return SavedPosition;
    } else {
      document.getElementById("sidenavmain").scrollIntoView({ behavior: "smooth" });
    }
  },
  
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/'];
  const authRequired = !publicPages.includes(to.path);
  const isAuthenticated = store.getters.isAuthenticated || localStorage.getItem("authToken");

  let modalBackground = document.querySelector('.modal-backdrop')
  if (modalBackground) {
    modalBackground.remove()
  }
  if (authRequired && !isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router;
