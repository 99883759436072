import axios from 'axios';
import jwt_decode from 'jwt-decode'
import router from '@/router/index.js';

export const BASE_URL = 'https://portal.shuleone.co.ke'; // Update with your backend URL
//export const BASE_URL = 'http://192.168.100.33:8091'; // Update with your backend URL
//export const BASE_URL = 'http://localhost:8091'; // Update with your backend URL



const apiClientMultipart = axios.create({
  baseURL: BASE_URL,
  processData: false,
  contentType: false,
  cache: false,
  headers: {
    'Content-Type': 'multipart/form-data'
  },
});



const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const tokenRefreshClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

function isAccessTokenExpired() {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    const decodedToken = jwt_decode(accessToken);
    const currentTime = Date.now() / 1000; // Convert to seconds
    return decodedToken.exp < currentTime;
  }
  return true; // No token or expired
}

apiClient.interceptors.request.use(async (config) => {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }


  // If access token is expired, try refreshing
  if (config.url !== '/api/auth/refresh' && config.url !== '/api/auth/signin' && isAccessTokenExpired()) {
    try {
      const refreshResponse = await tokenRefreshClient.post('/api/auth/refresh', { refreshToken });
      const newAccessToken = refreshResponse.data.accessToken;

      // Update the stored access token
      localStorage.setItem('accessToken', newAccessToken);

      // Set the new access token in the request headers
      config.headers.Authorization = `Bearer ${newAccessToken}`;
    } catch (error) {
      console.error('Error refreshing token:', error);
      router.push('/');
    }
  }

  return config;
}, (error) => {
  router.push('/').catch(() => {});
  console.log(error);
  //return Promise.reject(error);
});




apiClientMultipart.interceptors.request.use(async (config) => {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }


  // If access token is expired, try refreshing
  if (config.url !== '/api/auth/refresh' && isAccessTokenExpired()) {
    try {
      const refreshResponse = await tokenRefreshClient.post('/api/auth/refresh', { refreshToken });
      const newAccessToken = refreshResponse.data.accessToken;

      // Update the stored access token
      localStorage.setItem('accessToken', newAccessToken);

      // Set the new access token in the request headers
      config.headers.Authorization = `Bearer ${newAccessToken}`;
    } catch (error) {
      console.error('Error refreshing token:', error);
      router.push('/');
    }
  }

  return config;
}, (error) => {
  router.push('/').catch(() => {});
  console.log(error);
  //return Promise.reject(error);
});

export default {
  // Authenticate user and get JWT token
  async login(username, password) {
    const response = await apiClient.post('/api/auth/signin', {
      username,
      password,
    });

    return response.data;
  },

  logout() {
    localStorage.clear();
    router.push('/');
  },

  async getImage(imageName, type) {
    const url = `/api/images/getimage/${imageName}/${type}`;
    return apiClient.get(url, { responseType: 'blob' });
  },

  async fetchindexpagedata() {
    const response = await apiClient.get('/api/index/getindexpagedata');
    return response.data[0];
  },

  async getclasseswithall() {
    const response = await apiClient.get('/api/index/getclsseswithall');
    return response.data;
  },

  async getactiveclassesandallumni() {
    const response = await apiClient.get('/api/index/getactiveclassesandallumni');
    return response.data;
  },

  async getstreams() {
    const response = await apiClient.get('/api/index/getstreams');
    return response.data;
  },

  async getcounty() {
    const response = await apiClient.get('/api/index/getcounty');
    return response.data;
  },

  async getsubcounty() {
    const response = await apiClient.get('/api/index/getsubcounty');
    return response.data;
  },

  async gethostels() {
    const response = await apiClient.get('/api/index/gethostels');
    return response.data;
  },

  async getAllStudents() {
    const response = await apiClient.get('/api/reg/getActiveStudents');
    return response.data;
  },


  async getpopulationfigures() {
    const response = await apiClient.get('/api/index/getpopulationfigures');
    return response.data[0];
  },

  async getpopulationperclass() {
    const response = await apiClient.get('/api/index/getpopulationperclass');
    return response.data;
  },

 
  async getDataDynamic(path) {
    const response = await apiClient.get(path);
    return response.data;
  },

  apiClient,
  apiClientMultipart,
  
  // Other API methods for your application
};
